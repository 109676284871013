(function () {
  /**
   * @ngdoc component
   * @name abxPinDetails
   *
   * @param {Function} onUpdate - Called on pin updates.
   * @param {Object} pin - Pin responsible for
   * @param {Boolean} [saveOnBlur] - If inputs should save on blur. For a pin
   *    details, this might be desirable. But, for something like a pin
   *    creation, in which the inputs are simply part of their parent form,
   *    this would be undesirable. Defaults to false.
   * @param {Object} [disabledFieldTypes] - A hash of pinfield types to booleans
   *    if a type is omitted, it will not be disabled. Set the value at a given
   *    type to true to disable that type. This overrides the is_editable=true
   *    pinField property.
   * @param {Object} permissions - Optional user permissions object to determine if input
   *    components should be disabled. Null if flexible_client_permissions is disabled
   *
   * @description
   * Contains the editable fields for the given pin. This includes fields
   * that are directly on the pin, or pin values embedded in its `values`
   * field.
   *
   * Incoming pins will have their values evaulated (if available), and
   * pin value data will be used to select the floor filter for Room type
   * inputs.
   */
  angular
    .module("akitabox.ui.components.pinDetails")
    .component("abxPinDetails", {
      bindings: {
        onUpdate: "&abxOnUpdate",
        pin: "<abxPin",
        saveOnBlur: "<?abxSaveOnBlur",
        disabledFieldTypes: "<?abxDisabledFieldTypes",
        permissions: "<abxPermissions",
        onAttachmentUploadStart: "<?abxOnAttachmentUploadStart",
        onAttachmentUploadsFinished: "<?abxOnAttachmentUploadsFinished",
      },
      controller: AbxPinDetailsController,
      controllerAs: "vm",
      templateUrl:
        "app/core/ui/components/pin-details/pin-details.component.html",
    });

  function AbxPinDetailsController(
    // Angular
    $q,
    // Services
    FeatureFlagService,
    FieldGroupService,
    FloorService,
    OrganizationService,
    RoomService,
    ToastService,
    Utils,
    // Dialogs
    DownloadDocumentDialog,
    FilePreviewDialog,
    //Model
    models
  ) {
    var self = this;

    self.organization = OrganizationService.getCurrent();

    // Attributes
    self.disabledFieldTypes = self.disabledFieldTypes || {};
    self.isAsset = () => {
      return (
        self.pin &&
        self.pin.pinType &&
        self.pin.pinType.protected_type === "Asset"
      );
    };
    self.isRoom = () => {
      return !self.isAsset();
    };
    self.entityType = () => (self.isAsset() ? "asset" : "room");

    self.isQRCodeEditable = isQRCodeEditable();
    self.showLifeCycle = self.organization.show_rpi_fci;
    self.loadingLocation = true;
    self.loadingFields = true;
    self.installationYear = null;
    self.floorField = null;
    self.roomField = null;
    self.sqFeetField = null;
    self.fieldGroups = [];

    // Functions
    self.isDisabled = isDisabled;
    self.isEditable = isEditable;
    self.updatePinValue = updatePinValue;
    self.updateField = updateField;
    self.openAttachment = openAttachment;
    self.toggleFieldGroup = toggleFieldGroup;

    // =================
    // Lifecycle
    // =================

    self.$onChanges = function (changes) {
      if (
        changes.disabledFieldTypes &&
        !angular.isObject(self.disabledFieldTypes)
      ) {
        self.disabledFieldTypes = {};
      }
      if (changes.pin) {
        if (self.pin) {
          // Copy pin type to force inputs to recompile for a new pin of the same
          // pin type; otherwise, the state of the inputs for the old pin would
          // be reflected for the new pin
          if (!Utils.isSameModel(self.pin, changes.pin.previousValue)) {
            self.loadingLocation = true;
            self.loadingFields = true;
            self.floorField = null;
            self.roomField = null;
            self.floor = null;
            self.room = null;
            self.sqFeetField = null;

            self.installationYear = self.pin.installation_date
              ? new Date(self.pin.installation_date).getFullYear()
              : null;

            self.pinType = angular.copy(self.pin.pinType);

            for (var i = 0; i < self.pinType.fields.length; ++i) {
              var pinField = self.pinType.fields[i];
              // TODO: WEBAPP-15776
              if (pinField.data_type === "level") {
                self.floorField = pinField;
              }
              // TODO: WEBAPP-15777
              if (pinField.data_type === "room") {
                self.roomField = pinField;
              }
              // TODO: WEBAPP-15778
              if (pinField.name === "Square Feet") {
                self.sqFeetField = pinField;
              }
            }

            loadLocation().finally(() => {
              self.loadingLocation = false;
            });

            const allPinTypes = `type_name=null`;
            const specificPinType = `type_name=$eq,${self.pinType.name}`;
            const params = {
              sort: "order,asc",
              $or: `${allPinTypes};${specificPinType}`,
            };

            FieldGroupService.getAll(self.organization._id, params)
              .then(createFieldGroups)
              .finally(() => {
                self.loadingFields = false;
              });
          }
        } else {
          self.floorField = null;
          self.roomField = null;
          self.sqFeetField = null;
        }
      }
    };

    // =================
    // Public Functions
    // =================

    function isDisabled(pinField) {
      if (!pinField) return true;
      var dataTypeDisabled = self.disabledFieldTypes[pinField.data_type];
      if (self.permissions && pinField.data_type === "room") {
        return dataTypeDisabled || !self.permissions.canChangeLocation;
      }
      // (WEBAPP-9092) remove
      return dataTypeDisabled;
    }

    function isEditable(pinField) {
      if (!pinField) return false;
      if (self.permissions) {
        var canEditPin = self.permissions.canEditPin;
        // Allow room to be editable even if the user can't edit the pin (asset)
        // If the user can edit the pin but not the pin's room it will be
        // disabled via `isDisabled`, that way it looks like the other inputs
        if (pinField.data_type === "room" && !canEditPin) {
          return self.permissions.canChangeLocation;
        }
        return canEditPin;
      }
      // (WEBAPP-9092) remove
      return true;
    }

    /**
     * Update a standard field on a pin.
     *
     * @param {Object} event - Propagated event
     * @param {*} event.newValue - New value for the field
     * @param {String} field - Key of field being updated
     */
    function updateField(event, field) {
      if (!self.saveOnBlur || self.loadingFields || self.loadingLocation) {
        return;
      }

      switch (field) {
        case "level":
          return this.updatePinValue(event, this.floorField);
        case "room":
          return this.updatePinValue(event, this.roomField);
        case "installation_date":
          event.field = field;
          var installationYear = event.newValue;
          if (installationYear || installationYear === 0) {
            if (installationYear < 1000 || installationYear > 9999) {
              return $q.reject("Invalid year");
            }
            var installationDate = new Date(installationYear, 0, 2); // 01/02/YYYY
            event.newValue = installationDate.getTime();
          } else {
            event.newValue = null;
          }
          return self.onUpdate({
            $event: event,
          });
        default:
          event.field = field;

          return self.onUpdate({
            $event: event,
          });
      }
    }

    /**
     * Saves the pin with an updated pin value.
     *
     * @param {Object} event - Propagated event
     * @param {*} event.newValue - Value to save pin value with
     * @param {Object} pinField - Pin field associated with pin value
     */
    function updatePinValue(event, pinField) {
      if (!self.saveOnBlur) return;

      event.field = "values";
      event.pinField = pinField;

      return self.onUpdate({
        $event: event,
      });
    }

    // =================
    // Private Functions
    // =================

    function isQRCodeEditable() {
      if (self.permissions) {
        return (
          self.permissions.canAssociateQRCodes &&
          self.permissions.canDisassociateQRCodes
        );
      }
      // (WEBAPP-9092) remove
      return true;
    }

    /**
     * This function overwrites the function used
     * to open the attachment on <abx-attachment-thumbnail> component
     */
    function openAttachment($event) {
      if (!$event || !$event.document || !self.pin) {
        ToastService.showError("Document selected is not available");
      } else {
        const { document, documentOrder } = $event;
        const supportedExtension =
          models.DOCUMENT.FILE_PREVIEW_SUPPORTED_TYPES.includes(
            document.extension.toLowerCase()
          );

        if (supportedExtension) {
          FilePreviewDialog.show({
            pin: self.pin,
            documentId: document._id,
            supportMultipleFiles: true,
            documentOrder,
          });
        } else {
          var locals = {
            docs: [document],
          };
          DownloadDocumentDialog.show({
            locals: locals,
          }).catch(ToastService.showError);
        }
      }
    }

    function createFieldGroups(fieldGroups) {
      // Create view model field group list
      self.fieldGroups = [];
      var groupedFieldNames = [];
      for (var i = 0; i < fieldGroups.length; ++i) {
        var fieldGroup = fieldGroups[i];
        // Add pin fields according the order defined in `field_names`
        var fields = [];
        for (var fieldName of fieldGroup.field_names) {
          for (var j = 0; j < self.pinType.fields.length; ++j) {
            var pinField = self.pinType.fields[j];
            if (pinField.name === fieldName && !pinField.is_hidden) {
              fields.push(pinField);
              groupedFieldNames.push(pinField.name);
            }
          }
        }
        if (fields.length) {
          self.fieldGroups.push({
            ...fieldGroup,
            fields, // Pin field objects
            isExpanded: false, // UI expanded/collapsed state
          });
        }
      }

      // Add additional group with all fields that don't appear in a group
      var ungroupedPinFields = self.pinType.fields.filter((pinField) => {
        if (pinField.is_hidden) {
          return false;
        }
        // TODO: WEBAPP-15776
        if (self.floorField && pinField._id === self.floorField._id) {
          return false;
        }
        // TODO: WEBAPP-15777
        if (self.roomField && pinField._id === self.roomField._id) {
          return false;
        }
        // TODO: WEBAPP-15778
        if (self.sqFeetField && pinField._id === self.sqFeetField._id) {
          return false;
        }
        return !groupedFieldNames.includes(pinField.name);
      });

      if (ungroupedPinFields.length) {
        self.fieldGroups.push({
          name: "Custom Fields",
          fields: ungroupedPinFields,
        });
      }

      if (self.fieldGroups.length > 0) {
        for (const fieldGroup of self.fieldGroups) {
          if (fieldGroup.fields.length > 0) {
            // First field group that has fields
            fieldGroup.isExpanded = true;
            break;
          }
        }
      }
    }

    function toggleFieldGroup(fieldGroup) {
      fieldGroup.isExpanded = !fieldGroup.isExpanded;
    }

    function loadLocation() {
      var buildingId = self.pin.building._id || self.pin.building;
      var requests = [];
      if (self.pin.level) {
        var levelId = self.pin.level._id || self.pin.level;
        requests.push(
          FloorService.getById(buildingId, levelId, {}).then((level) => {
            self.level = level;
          })
        );
      } else {
        self.level = null;
      }
      if (self.pin.room) {
        var roomId = self.pin.room._id || self.pin.room;
        requests.push(
          RoomService.getById(buildingId, roomId, {}).then((room) => {
            self.room = room;
          })
        );
      } else {
        self.room = null;
      }
      return $q.all(requests);
    }
  }
})();
