import { FunctionComponent, useEffect, useState } from "react";
import {
  InsightsQueryData,
  QueryWidgetSelection,
} from "./InsightsWidgetSelectionDialog";
import { ExecuteQueryByWidgetId } from "@sisense/sdk-ui";

export interface ExecuteMultiQueryByWidgetIdsProps {
  queryWidgetSelections: QueryWidgetSelection[];
  dashboardOid: string;
  selectedDashboard: string;
  sisenseEnv: string;
  appContext: any;
  confirmSelections: (queryData?: InsightsQueryData[]) => void;
}

export const ExecuteMultiQueryByWidgetIds: FunctionComponent<
  ExecuteMultiQueryByWidgetIdsProps
> = ({ queryWidgetSelections, dashboardOid, confirmSelections }) => {
  const [queryData, setQueryData] = useState<InsightsQueryData[]>([]);
  const [widgetExecutedStates, setWidgetExecutedStates] = useState(
    new Map(
      queryWidgetSelections.map(
        (queryWidgetSelection: QueryWidgetSelection) => [
          queryWidgetSelection.widgetOid,
          false,
        ]
      )
    )
  );

  useEffect(() => {
    if (!queryWidgetSelections.length) {
      confirmSelections();
    } else if (queryData.length === queryWidgetSelections.length) {
      confirmSelections(queryData);
    }
  }, [confirmSelections, queryData, queryWidgetSelections.length]);

  return (
    <div>
      {queryWidgetSelections.length &&
        queryWidgetSelections.map((w, index) => {
          if (!widgetExecutedStates.get(w.widgetOid)) {
            return (
              <ExecuteQueryByWidgetId
                key={index}
                widgetOid={w.widgetOid}
                dashboardOid={dashboardOid}
                includeDashboardFilters={true}
              >
                {(data: any) => {
                  if (data) {
                    const insightsQueryData: InsightsQueryData = {
                      widgetType: w.widgetType,
                      widgetTitle: w.widgetTitle,
                      widgetId: w.widgetOid,
                      columns: data.columns,
                      rows: data.rows,
                    };
                    setQueryData([...queryData, insightsQueryData]);
                    setWidgetExecutedStates(
                      widgetExecutedStates.set(w.widgetOid, true)
                    );
                    return null;
                  }
                  return null;
                }}
              </ExecuteQueryByWidgetId>
            );
          }

          return null;
        })}
    </div>
  );
};
