import React, { useState } from "react";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";

import {
  Button,
  Tooltip,
  Box,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Divider,
  ListSubheader,
} from "@mui/material";

import {
  AccountTree,
  CurrencyExchange,
  FileUploadOutlined,
  LocationOff,
  PhotoLibrary,
  Place,
  Search,
  Settings,
  ViewSidebar,
} from "@mui/icons-material";

import { DetailSettings } from "./types";

const GridToolbar = ({
  onToggleHeaderFilters,
  showHeaderFilters,
  onExportClick,
  detailSettings,
  onDetailSettingChange,
  sidebarSetting,
  onSidebarSettingChange,
  showDecommissioned,
  onDecommissionedSettingChange,
}: {
  onToggleHeaderFilters: () => void;
  showHeaderFilters: boolean;
  onExportClick: () => void;
  detailSettings: DetailSettings;
  onDetailSettingChange: (newSetting: string, newState: boolean) => void;
  sidebarSetting: boolean;
  onSidebarSettingChange: (newState: boolean) => void;
  showDecommissioned: boolean;
  onDecommissionedSettingChange: (newState: boolean) => void;
}) => {
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingsAnchorEl(event.currentTarget);
  };
  const handleSettingsClose = () => {
    setSettingsAnchorEl(null);
  };
  const handleExportClick = () => {
    onExportClick();
  };

  const renderIcon = (iconName: string) => {
    let IconComponent;
    switch (iconName) {
      case "AccountTree":
        IconComponent = AccountTree;
        break;
      case "PhotoLibrary":
        IconComponent = PhotoLibrary;
        break;
      case "Place":
        IconComponent = Place;
        break;
      case "CurrencyExchange":
        IconComponent = CurrencyExchange;
        break;
      default:
        IconComponent = null;
        break;
    }
    if (IconComponent) return <IconComponent />;
    return null; // or a default icon
  };
  const open = Boolean(settingsAnchorEl);
  const id = open ? "view-settings-popover" : undefined;

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <Tooltip title={showHeaderFilters ? "Hide Filters" : "Show Filters"}>
        <Button
          onClick={onToggleHeaderFilters}
          color="primary"
          startIcon={<Search />}
        >
          {showHeaderFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Tooltip>
      <Box sx={{ flexGrow: 1 }} />
      <Button
        aria-controls="export-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleExportClick}
        startIcon={<FileUploadOutlined />}
      >
        Export
      </Button>
      <Button
        aria-describedby={id}
        color="primary"
        onClick={handleSettingsClick}
        startIcon={<Settings />}
      >
        View Settings
      </Button>
      {/* Popover for View Settings */}
      <Popover
        id={id}
        open={open}
        anchorEl={settingsAnchorEl}
        onClose={handleSettingsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          <ListSubheader>List Settings</ListSubheader>
          {Object.keys(detailSettings).map((settingName) => (
            <ListItem key={settingName}>
              <ListItemIcon>
                {renderIcon(detailSettings[settingName].iconName)}
              </ListItemIcon>
              <ListItemText primary={detailSettings[settingName].displayName} />
              <Switch
                checked={detailSettings[settingName].state}
                onChange={(event) => {
                  onDetailSettingChange(settingName, event.target.checked);
                }}
              />
            </ListItem>
          ))}
          <ListItem>
            <ListItemText inset primary="Hide/Show All" />
            <Switch
              checked={Object.values(detailSettings).every(
                (setting) => setting.state
              )}
              onChange={(event) => {
                const newState = event.target.checked;
                // Create a new object with all settings updated based on the switch's state
                // Look through all of the settings and use onDetailSettingChange to update them all
                // to either true/false
                Object.keys(detailSettings).forEach((settingName) => {
                  onDetailSettingChange(settingName, newState);
                });
              }}
            />
          </ListItem>
          <Divider />
          <ListSubheader>Drawer Settings</ListSubheader>
          <ListItem>
            <ListItemIcon>
              <ViewSidebar />
            </ListItemIcon>
            <ListItemText primary="Show Side Drawer" />
            <Switch
              checked={sidebarSetting}
              onChange={(event) => {
                onSidebarSettingChange(event.target.checked);
              }}
            />
          </ListItem>
          <Divider />
          <ListSubheader>Global Filters</ListSubheader>
          <ListItem>
            <ListItemIcon>
              <LocationOff />
            </ListItemIcon>
            <ListItemText primary="Show Decommissioned" />
            <Switch
              checked={showDecommissioned}
              onChange={(event) => {
                onDecommissionedSettingChange(event.target.checked);
              }}
            />
          </ListItem>
        </List>
      </Popover>
    </GridToolbarContainer>
  );
};

export default GridToolbar;
